import { IFilterModel } from '@a-type/interfaces';
import { useDebounce, useDispatch, useSelector } from '@a-type/ui/hooks';
import CountsUtils from '@a-type/ui/pages/counts/utils/counts.utils';
import { dataSourcesService } from '@a-type/ui/services';
import { setCountLoading, setCountTotalRecords } from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import CountsSummaryFilter from './counts-summary-filter.components';

export interface CountsSummaryProps {
  disabled?: boolean;
}

const CountsSummary = ({ disabled }: CountsSummaryProps) => {
  const { count, countLoading } = useSelector((state) => state.count);
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const dispatch = useDispatch();
  const countAbortController = useRef<AbortController>();

  // COUNT LOGIC
  const [hash, setHash] = useState<string>('');

  const updateCount = async () => {
    if (!currentDataSource || !count) {
      return;
    }

    // if there are no filters, set totalRecords from selected plan
    if (CountsUtils.isFilterEmpty(count)) {
      const totalRecords =
        currentDataSource.pricing?.find((x) => x._id === count.pricingPlanId)?.totalRecords ??
        currentDataSource.totalRecords ??
        0;
      dispatch(setCountTotalRecords(totalRecords));
      dispatch(setCountLoading(false));
      return;
    }

    if (countAbortController.current) {
      countAbortController.current.abort();
    }
    countAbortController.current = new AbortController();
    const { signal } = countAbortController.current;

    const responce = await dataSourcesService.count(currentDataSource, count, signal);
    if (responce?.status === 200 && signal.aborted === false) {
      dispatch(setCountTotalRecords(responce.data));
    }
    dispatch(setCountLoading(false));
  };

  const debounceUpdateCount = useDebounce(
    () => {
      if (!currentDataSource || !count || disabled) {
        return;
      }

      dispatch(setCountLoading(true));
      updateCount();
    },
    1500,
    [hash],
  );

  useEffect(() => {
    if (!count) return;

    const newHash = CountsUtils.calculateFiltersHash(count);
    if (newHash !== hash && !disabled) {
      setHash(newHash);
    }
  }, [count]);

  useEffect(() => {
    debounceUpdateCount();
  }, [hash]);

  // FILTERS LOGIC
  const [includedFilters, setIncludedFilters] = useState<{ [key: string]: IFilterModel[] }>({});
  const [showIncludedFilters, setShowIncludedFilters] = useState<boolean>(false);
  const [excludedFilters, setExcludedFilters] = useState<{ [key: string]: IFilterModel[] }>({});
  const [showExcludedFilters, setShowExcludedFilters] = useState<boolean>(false);
  const [groups, setGroups] = useState<{
    [key: string]: string;
  }>({});
  const [groupNames, setGroupNames] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (!currentDataSource) {
      return;
    }

    const r = currentDataSource.fields.reduce(
      (acc, field) => {
        if (field.isFilter) {
          return { ...acc, [field.name]: field.filterGroup };
        }
        return acc;
      },
      {} as { [key: string]: string },
    );
    setGroups(r);
  }, [currentDataSource]);

  useEffect(() => {
    if (!filtersGroups) {
      return;
    }

    const r = filtersGroups.reduce(
      (acc, group) => {
        return { ...acc, [group.code]: group.name };
      },
      {} as { [key: string]: string },
    );
    setGroupNames(r);
  }, [filtersGroups]);

  useEffect(() => {
    if (!count?.filters) {
      return;
    }

    const filters = Object.values(count.filters);
    const iFilters = filters
      .filter((filter: IFilterModel) => (filter.values || []).length > 0 && filter.mode === 'add')
      .sort((a, b) => a.sortOrder! - b.sortOrder!);
    const eFilters = filters
      .filter((filter: IFilterModel) => (filter.values || []).length > 0 && filter.mode === 'omit')
      .sort((a, b) => a.sortOrder! - b.sortOrder!);

    setIncludedFilters(
      iFilters.reduce(
        (acc, filter) => {
          const group = groups[filter._id];
          if (group) {
            acc[group] = acc[group] || [];
            acc[group].push(filter);
          }

          return acc;
        },
        {} as { [key: string]: IFilterModel[] },
      ),
    );
    setExcludedFilters(
      eFilters.reduce(
        (acc, filter) => {
          const group = groups[filter._id];
          if (group) {
            acc[group] = acc[group] || [];
            acc[group].push(filter);
          }

          return acc;
        },
        {} as { [key: string]: IFilterModel[] },
      ),
    );
    setShowIncludedFilters(iFilters.length > 0);
    setShowExcludedFilters(eFilters.length > 0);
  }, [count, groups]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        position: 'sticky',
        top: 0,
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${globalStyles.mainColors.grayColor}`,
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          pb: 1.5,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          Records
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          {countLoading === true ? (
            <RestartAltIcon
              sx={{
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(360deg)',
                  },
                  '100%': {
                    transform: 'rotate(0deg)',
                  },
                },
                animation: 'spin 2s linear infinite',
                color: globalStyles.mainColors.blueColor,
              }}
            />
          ) : (
            (count?.totalRecords?.toLocaleString() ?? 0)
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom:
            showIncludedFilters || showExcludedFilters
              ? `1px solid ${globalStyles.mainColors.grayColor}`
              : 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.blackColor,
              fontSize: 18,
              fontWeight: 400,
            }}
          >
            Database
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.blackColor,
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {count?.dataSourceName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.blackColor,
              fontSize: 18,
              fontWeight: 400,
            }}
          >
            Selected Plan
          </Typography>
          <Box
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {count?.pricingPlanName}
            </Typography>
            <Typography
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.greenColor,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {count?.pricingPlanPrice} Credits&nbsp;
              </Typography>
              / Record
            </Typography>
          </Box>
        </Box>
      </Box>

      {showIncludedFilters && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {Object.keys(includedFilters).map(
            (group) =>
              includedFilters[group].length > 0 && (
                <Box
                  key={group}
                  sx={{
                    '&:last-child': {
                      borderBottom: showExcludedFilters
                        ? `1px solid ${globalStyles.mainColors.grayColor}`
                        : 'none',
                    },
                    borderBottom: `1px solid ${globalStyles.mainColors.grayColor}`,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    pb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: globalStyles.mainColors.blackColor,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    {groupNames[group]}
                  </Typography>

                  {includedFilters[group].map((filter) => (
                    <CountsSummaryFilter disabled={disabled} filter={filter} key={filter._id} />
                  ))}
                </Box>
              ),
          )}
        </Box>
      )}

      {showExcludedFilters && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pb: 2,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.blackColor,
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            Omit Data
          </Typography>
          {Object.keys(excludedFilters).map(
            (group) =>
              excludedFilters[group].length > 0 && (
                <Box
                  key={group}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  {excludedFilters[group].map((filter) => (
                    <CountsSummaryFilter disabled={disabled} filter={filter} key={filter._id} />
                  ))}
                </Box>
              ),
          )}
        </Box>
      )}
    </Box>
  );
};

export default CountsSummary;
