import { useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { useGetUserDataSourcesQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Alert, AlertTitle, Box, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BuyListFormComponent from './components/buy-list-form.component';
import BuyListSummaryComponent from './components/buy-list-summary.component';

interface BuyListComponentProps {
  pageSource?: string;
}

const BuyListComponent = ({ pageSource }: BuyListComponentProps) => {
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.list);
  const { data: dataSources, isFetching: isLoading } = useGetUserDataSourcesQuery();
  const [recordsToBuy, setRecordsToBuy] = useState<number>(0);
  const [pricePerItem, setPricePerItem] = useState<number>(0); // price per 1 record
  const [hasAccess, setHasAccess] = useState<boolean | undefined>();

  useEffect(() => {
    if (list && dataSources) {
      setHasAccess(dataSources.some((source) => source._id === list.dataSourceId));
    }
  }, [list, dataSources]);

  useEffect(() => {
    if (!list) return;

    const price = Object.keys(list.filters || {}).reduce((acc, key) => {
      // Check if the filter has values
      const filter = list.filters![key];
      if (filter?.values.length) return acc + +filter.price;

      return acc;
    }, +list.pricingPlanPrice!);

    setPricePerItem(price);
  }, [list]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {list && hasAccess === false && !isLoading && (
        <Alert
          severity="warning"
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <AlertTitle>You don&apos;t have access to this database!</AlertTitle>
          You don&apos;t have access to this database anymore. Please contact your administrator to
          get access.
        </Alert>
      )}

      <Box
        sx={{
          alignItems: 'center',
          borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          display: 'flex',
          justifyContent: 'space-between',
          pb: 2,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <IconButton
            onClick={() =>
              navigate(
                pageSource === 'buyMore'
                  ? `${AppRoutes.Lists}/${list?._id}`
                  : `${AppRoutes.CountsList}/${list?.countId}`,
              )
            }
          >
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 700,
            }}
          >
            Buy List
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderRight: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            flexBasis: '50%',
            flexDirection: 'column',
            height: '100%',
            p: 3,
            pl: 1,
          }}
        >
          <BuyListFormComponent
            pricePerItem={pricePerItem}
            recordsToBuy={recordsToBuy}
            setRecordsToBuy={setRecordsToBuy}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexBasis: '50%',
            flexDirection: 'column',
            height: '100%',
            p: 3,
          }}
        >
          <BuyListSummaryComponent
            disabled={!hasAccess}
            pricePerItem={pricePerItem}
            recordsToBuy={recordsToBuy}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BuyListComponent;
