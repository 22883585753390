import { IFilterModel, IFilterValue } from '@a-type/interfaces';
import CancelFilled from '@a-type/ui/assets/icons/cancel-filled.svg';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import CloseIcon from '@mui/icons-material/Close';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Box, Chip, Icon, IconButton, Typography } from '@mui/material';

export interface CountsSummaryFilterProps {
  disabled: boolean | undefined;
  filter: IFilterModel;
}

const CountsSummaryFilter = ({ disabled = false, filter }: CountsSummaryFilterProps) => {
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();

  const removeFilter = (filterId: string) => {
    if (!count?.filters) return;

    const newFilters = { ...count.filters };
    delete newFilters[filterId];

    dispatch(
      setCount({
        ...count,
        filters: newFilters,
      }),
    );
  };

  const removeFilterValue = (filterId: string, value: IFilterValue) => {
    if (!count?.filters) return;

    const newValues = count.filters[filterId].values?.filter(
      (x: IFilterValue) => x.label !== value.label,
    );

    if (newValues.length === 0) {
      removeFilter(filterId);
    } else {
      dispatch(
        setCount({
          ...count,
          filters: {
            ...count.filters,
            [filterId]: {
              ...count.filters[filterId],
              values: newValues,
            },
          },
        }),
      );
    }
  };

  const isEmptyRange = (value: IFilterValue) => !!('label' in value && value.label === '');

  const filteredValues = filter.values.filter((x: IFilterValue) => !isEmptyRange(x));

  if (filteredValues.length === 0) return null;

  return (
    <Box
      key={filter._id}
      sx={{
        ':hover': {
          '.removeFilter': {
            display: 'flex',
          },
        },
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            width: '100%',
          }}
        >
          {filter.mode === 'omit' && (
            <NotInterestedIcon
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: '18px',
                mb: 0.25,
                rotate: '90deg',
              }}
            />
          )}
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.blackColor,
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {filter.name}
            {filter.units && filter.units.length > 0 && `, ${filter.units}`}
          </Typography>

          {filter.price && filter.price > 0 ? (
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              (
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.greenColor,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                +{filter.price} Credits&nbsp;
              </Typography>
              / Record)
            </Typography>
          ) : null}
        </Box>
        {!disabled && (
          <IconButton
            className="removeFilter"
            onClick={() => removeFilter(filter._id)}
            size="small"
            sx={{ display: 'none', p: 0.5, position: 'absolute', right: 0, top: -6 }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          width: '100%',
        }}
      >
        {filteredValues.map((x: IFilterValue) => (
          <Chip
            deleteIcon={
              <Icon>
                <img alt="CancelFilled" src={CancelFilled} />
              </Icon>
            }
            key={x.label}
            label={x.label}
            onDelete={disabled ? undefined : () => removeFilterValue(filter._id, x)}
            sx={{
              ':hover > span.material-icons': {
                display: 'block',
              },
              '> span.material-icons': {
                color: globalStyles.mainColors.grey74Color,
                display: 'none',
                position: 'absolute',
                right: 0,
                top: 1.4,
              },
              '> span.MuiChip-label': {
                px: 1.5,
              },
              backgroundColor: globalStyles.mainColors.whiteColor,
              border: `1px solid ${globalStyles.mainColors.grey74Color}`,
              color: globalStyles.mainColors.blackColor,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CountsSummaryFilter;
