import { DataSourceDateOptions } from '@a-type/enums';
import {
  IDataSourceField,
  IFilterDateRangeValue,
  IFilterModel,
  IFilterStringRangeValue,
} from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { LabelsUtils } from '@a-type/ui/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SelectedValuesControl from './selected-values-control.component';

export interface DateRangeSelectFilterProps {
  field: IDataSourceField;
}

interface DateBorders {
  maxDate?: Date;
  minDate?: Date;
}

interface ValidationResult {
  isValid: boolean;
  message?: string;
}

const DateRangeSelectFilter = (props: DateRangeSelectFilterProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<IFilterDateRangeValue[]>([]);
  const [dateBorders, setDateBorders] = useState<DateBorders>({});

  const MAX_RANGE_COUNT = 5;

  useEffect(() => {
    if (!count?.filters) return;

    if (!count.filters[field.name]) {
      const initialOption = {
        _id: uuidv4(),
        from: null,
        label: '',
        to: null,
      } as IFilterDateRangeValue;

      setSelectedOptions([initialOption]);

      const filter = {
        _id: field.name,
        mode: 'add',
        name: field.displayName,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType,
        units: field.units,
        values: [
          {
            _id: initialOption._id,
            from: null,
            label: '',
            to: null,
          } as IFilterDateRangeValue,
        ],
      } as IFilterModel;

      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name]: filter } }));
    } else {
      // Initialize selected options with the values from the count
      const values = count.filters[field.name].values.map((x) => {
        const value = x as IFilterStringRangeValue;
        return {
          _id: value._id || uuidv4(),
          from: value.from ? new Date(value.from) : null,
          label: value.label,
          to: value.to ? new Date(value.to) : null,
        } as IFilterDateRangeValue;
      });
      setSelectedOptions(values);
    }
  }, [count?.filters[field.name]?._id]);

  const noAvailableRangeAmount = (): boolean => {
    return selectedOptions.length >= MAX_RANGE_COUNT;
  };

  const formattedDate = (date: Date) => {
    try {
      const offset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - offset);
      return date.toISOString().slice(0, 10);
    } catch {
      return null;
    }
  };

  const addRange = () => {
    if (!count?.filters) return;
    if (noAvailableRangeAmount()) return;

    const newOption = {
      _id: uuidv4(),
      from: null,
      label: '',
      to: null,
    } as IFilterDateRangeValue;

    // Update local state first
    setSelectedOptions([...selectedOptions, newOption]);

    // Then update the global state
    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values: [
              ...selectedOptions.map(
                (x) =>
                  ({
                    _id: x._id,
                    from: x.from ? formattedDate(x.from) : undefined,
                    label: x.label,
                    to: x.to ? formattedDate(x.to) : undefined,
                  }) as IFilterStringRangeValue,
              ),
              {
                _id: newOption._id,
                from: undefined,
                label: '',
                to: undefined,
              } as IFilterStringRangeValue,
            ],
          },
        },
      }),
    );
  };

  const removeRange = (index: number) => {
    if (!count?.filters) return;

    // Update local state first
    const newSelectedOptions = selectedOptions.filter((_, i) => i !== index);
    setSelectedOptions(newSelectedOptions);

    // Then update the global state
    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values: newSelectedOptions.map(
              (x) =>
                ({
                  _id: x._id,
                  from: x.from ? formattedDate(x.from) : undefined,
                  label: x.label,
                  to: x.to ? formattedDate(x.to) : undefined,
                }) as IFilterStringRangeValue,
            ),
          },
        },
      }),
    );
  };

  const validateDateOptions = (option: IFilterDateRangeValue): ValidationResult => {
    // dates only
    const fromDate = option.from ? new Date(new Date(option.from).setHours(0, 0, 0, 0)) : null;
    const toDate = option.to ? new Date(new Date(option.to).setHours(0, 0, 0, 0)) : null;
    const maxDate = dateBorders.maxDate
      ? new Date(new Date(dateBorders.maxDate).setHours(0, 0, 0, 0))
      : undefined;
    const minDate = dateBorders.minDate
      ? new Date(new Date(dateBorders.minDate).setHours(0, 0, 0, 0))
      : undefined;

    if (fromDate === null && toDate === null)
      return { isValid: false, message: 'Both dates cannot be empty' };

    if (fromDate && toDate && fromDate > toDate)
      return { isValid: false, message: 'Start date cannot be after end date' };

    if (fromDate && ((maxDate && fromDate > maxDate) || (minDate && fromDate < minDate)))
      return { isValid: false, message: 'Start date out of range' };
    if (toDate && ((maxDate && toDate > maxDate) || (minDate && toDate < minDate)))
      return { isValid: false, message: 'End date out of range' };

    return { isValid: true };
  };

  const updateRange = (index: number, key: 'from' | 'to', value?: Date | null) => {
    const currentOption = selectedOptions[index];

    const newFrom = key === 'from' ? value : currentOption.from;
    const newTo = key === 'to' ? value : currentOption.to;

    const label = LabelsUtils.getDateRangeLabel(newFrom, newTo);

    setSelectedOptions(
      selectedOptions.map((option, i) =>
        i === index ? { ...option, from: newFrom, label, to: newTo } : option,
      ),
    );
  };

  const updateFilter = (values: IFilterDateRangeValue[]) => {
    if (!count?.filters?.[field.name]) return;

    // validate date ranges
    const areAllOptionsValid = values.every((option) => validateDateOptions(option).isValid);
    if (!areAllOptionsValid) return;

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values: values.map(
              (x) =>
                ({
                  _id: x._id,
                  from: x.from ? formattedDate(x.from) : undefined,
                  label: x.label,
                  to: x.to ? formattedDate(x.to) : undefined,
                }) as IFilterStringRangeValue,
            ),
          },
        },
      }),
    );
  };

  const setDateOptions = () => {
    const today = new Date();

    switch (field.dateOptions) {
      case DataSourceDateOptions.FUTURE_DATE:
        setDateBorders({
          maxDate: undefined,
          minDate: today,
        });
        break;

      case DataSourceDateOptions.PAST_DATE:
        setDateBorders({
          maxDate: today,
          minDate: undefined,
        });
        break;

      default:
        setDateBorders({
          maxDate: undefined,
          minDate: undefined,
        });
        break;
    }
  };

  useEffect(() => {
    setDateOptions();

    const timeOutId = setTimeout(() => updateFilter(selectedOptions), 500);
    return () => clearTimeout(timeOutId);
  }, [selectedOptions]);

  useEffect(() => {
    if (!count?.filters?.[field.name]?.values.length) {
      setSelectedOptions([
        {
          _id: uuidv4(),
          from: null,
          label: '',
          to: null,
        },
      ]);
    }
  }, [count?.filters?.[field.name]?.values]);

  const isAddButtonDisabled = useMemo(() => {
    if (noAvailableRangeAmount()) return true;

    const areAllRangesValid = selectedOptions.every(
      (option) => validateDateOptions(option).isValid,
    );

    return !areAllRangesValid;
  }, [selectedOptions, MAX_RANGE_COUNT]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: 'auto',
        justifyContent: 'space-between',
        p: 1.5,
        width: '100%',
      }}
    >
      <SelectedValuesControl field={field} />

      {selectedOptions.map((option, index) => {
        const validationResult = validateDateOptions(option);

        return (
          <Box
            key={`date-range-${option._id}`}
            sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'space-between' }}
          >
            <DatePicker
              maxDate={option.to ? option.to : dateBorders.maxDate}
              minDate={dateBorders.minDate}
              onChange={(date) => updateRange(index, 'from', date)}
              slotProps={{
                textField: {
                  error: !validationResult.isValid,
                  helperText: !validationResult.isValid ? validationResult.message : '',
                  label: 'From',
                  size: 'small',
                  sx: { flexGrow: 1 },
                  variant: 'outlined',
                },
              }}
              value={option.from}
            />

            <DatePicker
              maxDate={dateBorders.maxDate}
              minDate={option.from ? option.from : dateBorders.minDate}
              onChange={(date) => updateRange(index, 'to', date)}
              slotProps={{
                textField: {
                  error: !validationResult.isValid,
                  helperText: !validationResult.isValid ? validationResult.message : '',
                  label: 'To',
                  size: 'small',
                  sx: { flexGrow: 1 },
                  variant: 'outlined',
                },
              }}
              value={option.to}
            />

            <IconButton
              onClick={() => removeRange(index)}
              sx={{ mb: !validationResult.isValid ? 3 : 0, p: 0.5 }}
            >
              <CancelIcon sx={{ color: globalStyles.mainColors.grey74Color, fontSize: '18px' }} />
            </IconButton>
          </Box>
        );
      })}

      <Box sx={{ display: 'flex' }}>
        <Button
          color="info"
          disabled={isAddButtonDisabled}
          onClick={addRange}
          size="small"
          startIcon={<AddCircleIcon />}
        >
          Add a range
        </Button>
      </Box>
    </Box>
  );
};

export default DateRangeSelectFilter;
